export type OrderCancellationFieldSetting = {
  dayOfWeek: string
  cancellationLeadTime: number
  cancellationCutOffTime: Date
  id?: string
}

export const productNameToBusinessLine = {
  OnSite: 'RMC',
  Hub: 'CEM/AGG'
}

export const dayOrder = {
  MON: 0,
  TUE: 1,
  WED: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
  SUN: 6,
  HOL: 7
}

export type OrderCancellationConfig = {
  countryCode: string
  orgUnitId: string
  productName: string
  businessLine: string
  shippingType: string
  dispatchGroup?: string
  soldTo?: string
  businessDays: OrderCancellationFieldSetting[]
  id: string
}
