import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.store'

export type TfaData = {
  isTfaEnabled: boolean
  tfaType: 'authenticatorApp' | 'phoneNumber' | 'none'
  phoneNumberConfirmed: boolean
}
export const useTfaData = (userId?: string) => {
  return useQuery(
    ['tfa'],
    async () => {
      const response: AxiosResponse<TfaData> = await api.get(`tfa/${userId}`)
      return response
    },
    {retry: false, refetchOnWindowFocus: false, enabled: !!userId}
  )
}
