import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '18px',
    fontWeight: 600
  },
  textField: {
    borderRadius: '4px',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    padding: '6px 16px'
  },
  inputLabel: {
    color: '#54708C',
    padding: '6px 16px'
  }
}))
