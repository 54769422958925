import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(2)
  },
  timeInputField: {
    width: '150px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      border: '1.5px solid rgba(0, 39, 77, 0.15)'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid rgba(0, 39, 77, 0.15) !important'
    }
  }
}))
