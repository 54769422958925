import {FormProvider, useForm} from 'react-hook-form'
import {OrderCancellationConfigurationDetails} from './components/OrderCancellationConfigurationDetails'
import {OrderCancellationConfig} from './types/OrderCancellationConfig'
import {useHistory, useParams} from 'react-router'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useOrderCancellationConfigsEditMutation} from './hooks/useOrderCancellationConfigsMutation'
import {useOrderCancellationConfigsQuery} from './hooks/useOrderCancellationConfigsQuery'

export const OrderCancellationConfigurationEdit = () => {
  const {configId} = useParams<{
    configId?: string
  }>()

  const {t} = useTranslation()
  const history = useHistory()
  const orderCancellationConfigsMutation = useOrderCancellationConfigsEditMutation()

  const orderCancellationConfigQueryInfo = useOrderCancellationConfigsQuery()

  const methods = useForm<OrderCancellationConfig>({
    defaultValues: {} as OrderCancellationConfig,
    mode: 'onChange'
  })

  useEffect(() => {
    if (configId && orderCancellationConfigQueryInfo.data) {
      const config = orderCancellationConfigQueryInfo.data.find((config) => config.id === configId)
      if (config) {
        methods.reset(config)
      }
    }
  }, [configId, orderCancellationConfigQueryInfo.data, methods])

  const handleSaveButtonClick = async (data: OrderCancellationConfig) => {
    try {
      await orderCancellationConfigsMutation.mutateAsync(data)
    } finally {
      history.goBack()
    }
  }

  if (orderCancellationConfigQueryInfo.isLoading || !orderCancellationConfigQueryInfo.data) {
    return <div>{t('configurations.hconnect.orderCancellation.configurationDetails.loading')}</div>
  }

  return (
    <FormProvider {...methods}>
      <OrderCancellationConfigurationDetails
        configId={configId}
        onSaveButtonClick={methods.handleSubmit(handleSaveButtonClick)}
        isSubmitting={orderCancellationConfigsMutation.isLoading}
      />
    </FormProvider>
  )
}
