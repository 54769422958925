import {useMutation, useQueryClient} from '@tanstack/react-query'
import {t} from 'i18next'
import {useSnackbar} from 'notistack'

import {api} from '../../../../../App.store'
import {OrderCancellationConfig} from '../types/OrderCancellationConfig'

import {OrderCancellationConfigQueryKey, ProductConfigsQueryKey} from './queryKeys'

const editOrderCancellationConfig = async (orderCancellationConfig: OrderCancellationConfig) => {
  await api.put<OrderCancellationConfig[]>(
    `/product-configs/work-calendars/${orderCancellationConfig.id}`,
    orderCancellationConfig
  )
  return orderCancellationConfig
}
type MutationType = 'edit'

export const useOrderCancellationConfigsEditMutation = () => {
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    editOrderCancellationConfig,
    getMutationHandlers(queryClient, enqueueSnackbar, 'edit')
  )
}

const getMutationHandlers = (queryClient, enqueueSnackbar, mutationType: MutationType) => ({
  onSuccess: () => {
    const successMessageKey = {
      edit: 'configurations.hconnect.orderCancellation.configurationList.editSuccess'
    }[mutationType]

    enqueueSnackbar(t(successMessageKey), {variant: 'success'})
    queryClient.invalidateQueries([ProductConfigsQueryKey, OrderCancellationConfigQueryKey])
  },
  onError: (error: any) => {
    const errorMessageKey = {
      edit: 'configurations.hconnect.orderCancellation.configurationList.editError'
    }[mutationType]

    enqueueSnackbar(t(errorMessageKey), {variant: 'error'})
    console.error('Mutation error:', error)
  }
})
