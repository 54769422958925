import {trackEvent} from '@hconnect/common/logging/Analytics'
import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../../../App.store'
import {OrderCancellationConfig} from '../types/OrderCancellationConfig'

import {OrderCancellationConfigQueryKey, ProductConfigsQueryKey} from './queryKeys'

const orderChangeConfigurationFetcher: QueryFunction<OrderCancellationConfig[]> = async ({
  queryKey
}) => {
  const response = await api.get<OrderCancellationConfig[]>('/product-configs/work-calendars')
  return response.data
}

export const useOrderCancellationConfigsQuery = () => {
  return useQuery<OrderCancellationConfig[], AxiosError>(
    [ProductConfigsQueryKey, OrderCancellationConfigQueryKey],
    orderChangeConfigurationFetcher,
    {
      onError: (error) => {
        console.error(error)
        trackEvent('adminConsoleError', {
          product: 'adminConsole',
          date: new Date().toISOString(),
          errorCode: error.response?.status,
          component: 'useOrderCancellationConfigsQuery',
          endpoint: '/product-configs/work-calendars'
        })
      }
    }
  )
}
