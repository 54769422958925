import {Box, TextField, Paper} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'

import {useStyles} from './OrderCancellationLeadTimes.styles'
import {dayOrder, OrderCancellationConfig} from '../types/OrderCancellationConfig'
import {useMemo} from 'react'

type Props = {
  businessDays: OrderCancellationConfig['businessDays']
  handleFieldChange: (dayOfWeek: string, field: string, value: string | boolean) => void
}

export const OrderCancellationLeadTimes = ({businessDays, handleFieldChange}: Props) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const columns = [
    {
      field: 'dayOfWeek',
      headerName: t('configurations.hconnect.orderCancellation.configurationDetails.dayOfWeek')
    },
    {
      field: 'cancellationLeadTime',
      headerName: t(
        'configurations.hconnect.orderCancellation.configurationDetails.cancellationLeadTime'
      )
    },
    {
      field: 'cancellationCutOffTime',
      headerName: t(
        'configurations.hconnect.orderCancellation.configurationDetails.cancellationCutOffTime'
      )
    }
  ]

  const sortBusinessDays = useMemo(() => {
    return [...businessDays].sort((a, b) => dayOrder[a.dayOfWeek] - dayOrder[b.dayOfWeek])
  }, [businessDays])

  return (
    <Box className={classes.container}>
      <Paper>
        <ResponsiveTable
          columns={columns}
          rows={sortBusinessDays.map((day) => ({
            dayOfWeek: day.dayOfWeek,
            cancellationLeadTime: (
              <TextField
                type="number"
                value={day.cancellationLeadTime}
                onChange={(e) => {
                  const value = e.target.value
                  if (Number(value) >= 0) {
                    handleFieldChange(day.dayOfWeek, 'cancellationLeadTime', value)
                  }
                }}
                className={classes.timeInputField}
                data-test-id={`order-cancellation-business-days-cancellation-lead-time-${day.dayOfWeek}`}
              />
            ),
            cancellationCutOffTime: (
              <TextField
                type="time"
                inputProps={{step: 1}}
                value={day.cancellationCutOffTime}
                onChange={(e) => {
                  const value = e.target.value
                  handleFieldChange(day.dayOfWeek, 'cancellationCutOffTime', value)
                }}
                className={classes.timeInputField}
                data-test-id={`order-cancellation-business-days-cancellation-cutoff-time-${day.dayOfWeek}`}
              />
            )
          }))}
          keyField="dayOfWeek"
          onSort={() => {}}
          isMobile={isMobile}
          data-test-id="order-cancellation-configuration-lead-times"
        />
      </Paper>
    </Box>
  )
}
