import {
  Content,
  LoadingButton,
  Page,
  TitleNav,
  useBreakPoints,
  useTranslation
} from '@hconnect/uikit'
import {ArrowBackIos, ArrowForward} from '@mui/icons-material'
import {Box, Grid, Paper, Typography} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useHistory} from 'react-router'
import {useStyles} from '../../OrderChange/components/OrderChangeConfigurationDetails.styles'
import {useAllowedHConnectConfigCountries} from '../../hooks/useAllowedHConnectConfigCountries'
import {OrderCancellationConfig} from '../types/OrderCancellationConfig'

import {OrderCancellationLeadTimes} from './OrderCancellationLeadTimes'
import {OrderCancellationConstraints} from './OrderCancellationConstraints'

type Props = {
  configId?: string
  onSaveButtonClick: () => void
  isSubmitting: boolean
}

export const OrderCancellationConfigurationDetails = ({onSaveButtonClick, isSubmitting}: Props) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {classes} = useStyles({isMobile})
  const history = useHistory()

  const configurableHConnectCountries = useAllowedHConnectConfigCountries()

  const handleFieldChange = (dayOfWeek: string, field: string, value: string | boolean) => {
    const currentBusinessDays = formMethods.getValues('businessDays')
    const newBusinessDays = [...currentBusinessDays]
    const index = newBusinessDays.findIndex((day) => day.dayOfWeek === dayOfWeek)
    if (index !== -1) {
      newBusinessDays[index] = {
        ...newBusinessDays[index],
        [field]: field === 'cancellationLeadTime' ? Number(value) : value
      }
    }
    formMethods.setValue('businessDays', newBusinessDays)
  }

  const formMethods = useFormContext<OrderCancellationConfig>()

  const businessDays = formMethods?.watch('businessDays')

  if (!businessDays) {
    return <div>{t('configurations.hconnect.orderCancellation.configurationDetails.loading')}</div>
  }

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        title={
          !isTablet && (
            <Box className={classes.pageTitle}>
              {t('configurations.hconnect.orderCancellation.configurationDetails.title')}
            </Box>
          )
        }
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={
                isTablet
                  ? t('configurations.hconnect.orderCancellation.configurationDetails.title')
                  : t('configurations.hconnect.orderCancellation.configurationDetails.goBack')
              }
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography data-test-id={`order-cancellation-configuration-title-edit}`}>
                      {t('configurations.hconnect.orderChange.configurationDetails.goBack')}
                    </Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
          </Box>
        }
      >
        <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderCancellationConstraints countries={configurableHConnectCountries} />
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderCancellationLeadTimes
                businessDays={businessDays}
                handleFieldChange={handleFieldChange}
              />
              <Box className={classes.saveButtonBox}>
                <LoadingButton
                  startIcon={<ArrowForward fontSize="small" />}
                  variant="outlined"
                  color="primary"
                  style={{background: 'rgb(1, 106, 212', textTransform: 'none'}}
                  onClick={onSaveButtonClick}
                  loading={isSubmitting}
                  data-test-id="order-cancellation-configuration-save-button"
                >
                  {t('configurations.hconnect.orderCancellation.configurationDetails.buttonSave')}
                </LoadingButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Page>
    </Content>
  )
}
