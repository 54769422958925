import {FormControlLabel, Switch} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

type WidgetPermissionInputProps = {
  permissionKey: string
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  disabled?: boolean
  'data-test-id'?: string
}

export const WidgetPermissionInput = ({
  permissionKey,
  disabled,
  onChange,
  checked,
  label,
  'data-test-id': dataTestId
}: WidgetPermissionInputProps) => {
  const {classes} = useWidgetUserCreationStyles()
  return (
    <FormControlLabel
      value={permissionKey}
      disabled={disabled}
      control={
        <Switch
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={(_, checked) => onChange(checked)}
          data-test-id={dataTestId}
          classes={{
            track: clsx({
              [classes.trackEnabled]: checked
            })
          }}
        />
      }
      label={label}
      labelPlacement="end"
      data-test-id={`${dataTestId}-label`}
    />
  )
}
