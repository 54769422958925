import {Typography, useTranslation} from '@hconnect/uikit'
import {Box, TextField} from '@mui/material'
import {useFormContext, Controller} from 'react-hook-form'

import {OrderCancellationConfig, productNameToBusinessLine} from '../types/OrderCancellationConfig'

import {useStyles} from './OrderCancellationConstraints.styles'

type Props = {
  countries: string[]
}

const DataScopeNames = ['businessLine', 'orgUnitId', 'shippingType', 'soldTo']

export const OrderCancellationConstraints = ({countries}: Props) => {
  const {t} = useTranslation()
  const {control, getValues} = useFormContext<OrderCancellationConfig>()
  const {classes} = useStyles()

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <Typography className={classes.title}>
        {t('configurations.hconnect.orderCancellation.configurationDetails.constraintsTitle')}
      </Typography>
      <Controller
        name="countryCode"
        control={control}
        render={({field: controllerField}) => (
          <Box>
            <TextField
              {...controllerField}
              fullWidth
              label={t(
                'configurations.hconnect.orderCancellation.configurationDetails.countryCodeLabel'
              )}
              type="text"
              variant="standard"
              className={classes.textField}
              InputProps={{disableUnderline: true, readOnly: true}}
              InputLabelProps={{className: classes.inputLabel, shrink: true}}
              data-test-id="order-cancellation-configurations-country-code"
            />
          </Box>
        )}
      />
      {DataScopeNames.map((field) => (
        <Controller
          key={field}
          name={field as keyof OrderCancellationConfig}
          control={control}
          render={({field: controllerField}) => (
            <Box>
              <TextField
                {...controllerField}
                fullWidth
                label={t(`configurations.hconnect.orderCancellation.configurationDetails.${field}`)}
                type="text"
                variant="standard"
                className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  value:
                    field === 'businessLine'
                      ? productNameToBusinessLine[getValues('productName')]
                      : controllerField.value
                }}
                InputLabelProps={{className: classes.inputLabel, shrink: true}}
              />
            </Box>
          )}
        />
      ))}
    </Box>
  )
}
