import {Content, Page, ResponsiveTable, Typography, useBreakPoints} from '@hconnect/uikit'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Grid, IconButton, Paper} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useAllowedHConnectConfigCountries} from '../hooks/useAllowedHConnectConfigCountries'

import {useOrderCancellationConfigsQuery} from '../OrderCancellation/hooks/useOrderCancellationConfigsQuery'
import {useStyles} from '../OrderChange/OrderChange.styles'
import {
  OrderCancellationConfig,
  productNameToBusinessLine
} from '../OrderCancellation/types/OrderCancellationConfig'

export const OrderCancellation = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const {classes} = useStyles()

  const handleRowAction: HandleConfigurationAction = (configurationId, action) => {
    if (action === 'edit') {
      history.push(`/configuration/hconnect/order-cancellation/${configurationId}/edit`)
      return
    }
    console.error('Unknown action:', action)
  }

  const orderCancellationConfigQueryInfo = useOrderCancellationConfigsQuery()
  const configurableHConnectCountries = useAllowedHConnectConfigCountries()

  const filteredAndSortedData = useMemo(() => {
    return (
      orderCancellationConfigQueryInfo.data
        ?.filter((c) => configurableHConnectCountries.includes(c.countryCode))
        .sort((a, b) => {
          return a.orgUnitId.localeCompare(b.orgUnitId)
        }) ?? []
    )
  }, [orderCancellationConfigQueryInfo.data, configurableHConnectCountries])

  return (
    <Content>
      <Page
        data-test-id="page-order-cancellation-configurations"
        title={t('configurations.hconnect.orderCancellation.title')}
        classNameHeader={classes.pageHeader}
        boxed={false}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid item md={12} sm={12} xs={0}>
          <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
            <ResponsiveTable
              keyField="id"
              columns={OrderCancellationConfigurationsRows(handleRowAction)}
              rows={filteredAndSortedData}
              tableHeight="70vh"
              onSort={() => ({})}
              loading={orderCancellationConfigQueryInfo.isLoading}
              enableHorizontalScroll={true}
              stickyHeader={true}
              enablePinning={true}
              isMobile={isMobile}
              isRowSelectable={isMobile ? undefined : () => true}
            />
          </Paper>
        </Grid>
      </Page>
    </Content>
  )
}

const OrderCancellationConfigurationsRows = (
  handleAction: HandleConfigurationAction
): {
  field: string
  headerName: string
  renderCell: (row: OrderCancellationConfig) => JSX.Element
}[] => {
  const {t} = useTranslation()

  return [
    {
      field: 'action',
      headerName: t('configurations.hconnect.orderCancellation.configurationList.action'),
      renderCell: (row: OrderCancellationConfig) => (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center'
          }}
        >
          <IconButton
            data-test-id={`action-edit-json-${row.id}`}
            onClick={() => handleAction(row.id, 'edit')}
            aria-label="edit"
            style={{boxShadow: 'none'}}
          >
            <ModeEditOutlineIcon style={{color: '#016AD4'}} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'country',
      headerName: t('configurations.hconnect.orderCancellation.configurationList.country'),
      renderCell: (row) => (
        <Typography data-test-id={`cell-country-${row.id}`}>{row.countryCode}</Typography>
      )
    },
    {
      field: 'orgUnitId',
      headerName: t('configurations.hconnect.orderCancellation.configurationList.orgUnitId'),
      renderCell: (row) => <Typography>{row.orgUnitId}</Typography>
    },
    {
      field: 'shippingType',
      headerName: t('configurations.hconnect.orderCancellation.configurationList.shippingType'),
      renderCell: (row) => <Typography>{row.shippingType}</Typography>
    },
    {
      field: 'businessLine',
      headerName: t('configurations.hconnect.orderCancellation.configurationList.businessLine'),
      renderCell: (row) => {
        const businessLine = productNameToBusinessLine[row.productName]
        return <Typography>{businessLine}</Typography>
      }
    }
  ]
}

type HandleConfigurationAction = (configurationId: string, action: 'edit') => void
